import * as React from "react";
import { Link } from "gatsby-plugin-react-i18next";

const NameSinglePage = () => {
	return (
		<Link to="/">
			<div className="myName">
				<div className="nameKana">
					<div className="nameJoseKana">ホセ</div>
					<div className="nameTomasKana">トマス</div>
					<div className="nameSierraKana">シエラ</div>
				</div>
				<div className="nameAlphabetSinglePage">
					<div className="nameJoseAlphabet">José&nbsp;</div>
					<div className="nameTomasAlphabet">Tomás&nbsp;</div>
					<div className="nameSierraAlphabet">Sierra</div>
				</div>
			</div>
		</Link>
	);
};
export default NameSinglePage;
