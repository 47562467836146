import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../layouts/index";
import NameSinglePage from "../../components/NameSinglePage";
import GoBack from "../../components/GoBack";
import TooltipComponent from "../../components/TooltipComponent";

const Contact = () => {
	return (
		<Layout>
			<div className="singlePage">
				<NameSinglePage />
				<div className="mainDescription">
					{/* First Element - Email */}
					<div className="paragraphDescription contactMail">
						<a href="mailto:tomasjsierragmail.com">tomasjsierra@gmail.com</a>
					</div>
					<div className="contactMailCopyIcon">
						<TooltipComponent />
					</div>
				</div>
			</div>
			<GoBack />
		</Layout>
	);
};
export default Contact;

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
