import * as React from "react";
import { navigate } from "gatsby";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

const GoBack = () => {
	const { t } = useTranslation();
	return (
		<div className="goBack">
			<a className="goBackLink" onClick={() => navigate(-1)}>
				←&nbsp;{t("all-goback")}
			</a>
		</div>
	);
};
export default GoBack;

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
